// src/Components/BillDetail/BillDetail.jsx
import React from "react";
import "./BillDetail.css";

const BillDetail = ({ totalItems, totalPrice, discount, deliveryCharge, totalAmount }) => {
  return (
    <div className="bill-details-container">
      <h2 className="bill-details-heading">Price Details</h2>
      <div className="bill-details-row">
        <span>Price ({totalItems} items)</span>
        <span>${totalPrice}</span>
      </div>
      <div className="bill-details-row">
        <span>Discount</span>
        <span>-${discount}</span>
      </div>
      <div className="bill-details-row">
        <span>Coupon Discount</span>
        <span>-$0</span>
      </div>
      <div className="bill-details-row">
        <span>Delivery Charges</span>
        <span>${deliveryCharge}</span>
      </div>
      <hr className="bill-details-divider" />
      <div className="bill-details-row bill-details-total">
        <span>Total Amount</span>
        <span>${totalAmount}</span>
      </div>
    </div>
  );
};

export default BillDetail;
