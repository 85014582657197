import React from "react";
import RouteComponent from "./RouteComponent/RouteComponent";

function App() {
  return (
      <>
        <RouteComponent />
      </>
  );
}

export default App;
