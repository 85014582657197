// src/Components/AddAddress/AddAddress.jsx
import React, { useState } from "react";
import styled from "styled-components";
import BillDetail from "../BillDetail/BillDetail";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddAddressContainer = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #ff424d;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;

  &:focus {
    border-color: #ff424d;
  }
`;

const Select = styled.select`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;

  &:focus {
    border-color: #ff424d;
  }
`;

const Button = styled.button`
  background-color: #ff424d;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e63a44;
  }
`;

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

const AddAddress = () => {
  const [address, setAddress] = useState({
    fullName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "USA",
    phoneNumber: "",
    email: "",
  });

  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart.items);
  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const deliveryCharge = 10;
  const totalAmount = totalPrice + deliveryCharge;

  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Address submitted:", address);
    navigate("/payment");
  };

  return (
    <div className="container">
      <AddAddressContainer>
        <Title>Add Address</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={address.fullName}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={address.phoneNumber}
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={address.email}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="addressLine1"
            placeholder="Address Line 1"
            value={address.addressLine1}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="addressLine2"
            placeholder="Address Line 2"
            value={address.addressLine2}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="city"
            placeholder="City"
            value={address.city}
            onChange={handleChange}
            required
          />
          <Select
            name="state"
            value={address.state}
            onChange={handleChange}
            required
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
          <Input
            type="text"
            name="postalCode"
            placeholder="Postal Code"
            value={address.postalCode}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="country"
            placeholder="Country"
            value={address.country}
            readOnly
          />
          

          <BillDetail
            totalItems={totalItems}
            totalPrice={totalPrice}
            discount={0}
            deliveryCharge={deliveryCharge}
            totalAmount={totalAmount}
          />
          <Button type="submit" className="mt-3">
            Proceed to Payment
          </Button>
        </Form>
      </AddAddressContainer>
    </div>
  );
};

export default AddAddress;
