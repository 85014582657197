import React from "react";
import Cart from "../Components/Cart/Cart";
import NavBar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";

const CartLayOut = () => {
  return (
    <div>
      <NavBar />
      <Cart />
      <Footer />
    </div>
  );
};

export default CartLayOut;
