// src/components/Route.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeLayout from '../LayOut/HomeLayout';
import CartLayOut from '../LayOut/CartLayOut';
import ProductLayOut from '../LayOut/ProductLayOut';
import AddAddress from '../Components/AddAddress/AddAddress';

const RouteComponent = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomeLayout />} />
        <Route path="/cart" element={<CartLayOut />} />
        <Route path="/product" element={<ProductLayOut />} />
        <Route path="/add-address" element={<AddAddress />} />
      </Routes>
    </Router>
  );
};

export default RouteComponent;
